<template>
  <div>
    <div class="refund" v-if="orderDetail.apply_refund == 1">
      <div class="module">
        <div class="left">
          <div class="refund-text">用户发起退款申请，待商家受理</div>
        </div>
        <div class="right">
          <el-button type="primary" @click="agreeRow">同意</el-button>
          <el-button @click="refuseRow">拒绝</el-button>
        </div>
      </div>
    </div>
    <div class="grid-template" v-loading="loading">
      <div class="left">
        <div class="item" v-if="orderDetail.apply_refund != 0">
          <div class="module">
            <div class="title">售后信息</div>
            <div class="info">
              <div class="info-item">
                <div class="label">退款单号</div>
                <div class="value">{{ orderDetail.orderid }}</div>
              </div>
              <div class="info-item">
                <div class="label">交易金额</div>
                <div class="value">
                  ￥{{ orderDetail.order_price.toFixed(2) | placeholder }}
                </div>
              </div>
              <div class="info-item">
                <div class="label">退款金额</div>
                <div class="value">
                  ￥{{ orderDetail.order_price.toFixed(2) | placeholder }}
                </div>
              </div>
              <div class="info-item">
                <!-- <div class="label">退款商品</div> -->
                <div class="value">
                  <el-table :data="orderDetail.orderGoods" max-height="280px">
                    <el-table-column
                      min-width="250"
                      prop="specs"
                      label="商品名称/属性"
                    >
                      <template slot-scope="{ row }">
                        <div class="goods-name">
                          <div class="left">
                            <!-- 默认显示第一个商品规格 -->
                            <list-image
                              v-if="row"
                              fit="cover"
                              :width="40"
                              border-radius="5%"
                              :src="row.image"
                            />
                          </div>
                          <div class="right">
                            <div class="goods-attr">
                              {{ row.name }}
                            </div>
                            <div class="goods-specs">
                              {{ Object.values(row.specs.attrs).join(';') }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      min-width="150"
                      prop="price"
                      label="单价数量"
                    >
                      <template slot-scope="{ row }">
                        <div class="price_count">
                          <div v-if="row.price" class="price">
                            ￥{{ row.price }}
                          </div>
                          <div v-if="row.integral" class="price">
                            {{ row.integral }}积分
                          </div>
                          <div class="count">x {{ row.amount }}</div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div class="info-item">
                <div class="label">退款时间</div>
                <div class="value">
                  {{ orderDetail.apply_refund_time | placeholder }}
                </div>
              </div>

              <div class="info-item">
                <div class="label">退款状态</div>
                <div class="value">
                  <list-status-text
                    :type="
                      orderDetail.apply_refund == 1
                        ? 'warning'
                        : orderDetail.apply_refund == 2
                        ? 'success'
                        : orderDetail.apply_refund == 3
                        ? 'danger'
                        : 'info'
                    "
                    :text="
                      orderDetail.apply_refund == 1
                        ? '待商家受理'
                        : orderDetail.apply_refund_text
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="module">
            <div class="title">订单商品</div>
            <el-table :data="orderDetail.orderGoods" max-height="280px">
              <el-table-column
                min-width="250"
                prop="specs"
                label="商品名称/属性"
              >
                <template slot-scope="{ row }">
                  <div class="goods-name">
                    <div class="left">
                      <!-- 默认显示第一个商品规格 -->
                      <list-image
                        v-if="row"
                        fit="cover"
                        :width="40"
                        border-radius="5%"
                        :src="row.image"
                      />
                    </div>
                    <div class="right">
                      <div class="goods-attr">
                        {{ row.name }}
                      </div>
                      <div class="goods-specs">
                        {{ Object.values(row.specs.attrs).join(';') }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="150" prop="price" label="单价数量">
                <template slot-scope="{ row }">
                  <div class="price_count">
                    <div v-if="row.price" class="price">￥{{ row.price }}</div>
                    <div v-if="row.integral" class="price">
                      {{ row.integral }}积分
                    </div>
                    <div class="count">x {{ row.amount }}</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="order_price">
              <div class="text">
                商品总价：
                <span v-if="totalPrice(orderDetail.orderGoods) !== '0.00'"
                  >￥{{ totalPrice(orderDetail.orderGoods) }}</span
                >
                <span
                  v-if="
                    totalPrice(orderDetail.orderGoods) !== '0.00' &&
                    orderDetail.integral
                  "
                  >{{ ' + ' }}</span
                >
                <span v-if="orderDetail.integral"
                  >{{ orderDetail.integral }}积分</span
                >
              </div>
              <div class="text">
                订单运费：￥{{ totalFreight(orderDetail.orderGoods) }}
              </div>
              <!-- 改价先隐藏 -->
              <div class="text" v-if="false">商品改价：-￥0.00</div>
              <div class="total">
                总{{ totalNum(orderDetail.orderGoods) }}件，实付款<span
                  >￥{{ orderDetail.order_price.toFixed(2) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <div class="module">
            <div class="label-box">
              <div class="label">订单信息</div>
              <el-link type="primary" :underline="false" @click="goDetail"
                >详情</el-link
              >
            </div>
            <div class="info">
              <div class="info-item">
                <div class="label">订单状态</div>
                <div class="value">
                  <list-status-text
                    :type="
                      orderDetail.status == 1
                        ? 'warning'
                        : orderDetail.status == 0
                        ? 'success'
                        : orderDetail.status == 2
                        ? 'info'
                        : 'danger'
                    "
                    :text="orderDetail.status_text"
                  />
                </div>
              </div>
              <div class="info-item">
                <div class="label">订单编号</div>
                <div class="value">{{ orderDetail.orderid }}</div>
              </div>

              <div class="info-item">
                <div class="label">下单时间</div>
                <div class="value">{{ orderDetail.create_time }}</div>
              </div>
              <div class="info-item">
                <div class="label">商户号</div>
                <div class="value">{{ orderDetail.merchant }}</div>
              </div>
              <div class="info-item">
                <div class="label">支付方式</div>
                <div class="value">{{ orderDetail.pay_category_text }}</div>
              </div>

              <div class="info-item">
                <div class="label">支付时间</div>
                <div class="value">
                  {{ orderDetail.pay_time | placeholder }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="orderDetail.user">
          <div class="module">
            <div class="title">买家</div>
            <div class="info">
              <div class="info-item">
                <div class="label">用户名称</div>
                <div class="value">{{ orderDetail.user.nickname }}</div>
              </div>

              <div class="info-item">
                <div class="label">会内职务</div>
                <div class="value">
                  {{ orderDetail.user.title_name | placeholder }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="module">
            <div class="title">发货信息</div>
            <div class="info">
              <div class="info-item">
                <div class="label">收货人</div>
                <div class="value">{{ orderDetail.addressee }}</div>
              </div>

              <div class="info-item">
                <div class="label">联系方式</div>
                <div class="value">{{ orderDetail.phone | placeholder }}</div>
              </div>
              <div class="info-item">
                <div class="label">收货地区</div>
                <div class="value">
                  {{
                    orderDetail.region
                      ? orderDetail.region.split('#').join('/')
                      : orderDetail.region | placeholder
                  }}
                </div>
              </div>
              <div class="info-item">
                <div class="label">收货地址</div>
                <div class="value">{{ orderDetail.address | placeholder }}</div>
              </div>
              <div class="info-item">
                <div class="label">买家留言</div>
                <div class="value">{{ orderDetail.details | placeholder }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from '../api/order-list'
import ListStatusText from '@/base/components/List/ListStatusText'
import ListImage from '@/base/components/List/ListImage'
import { refundApplyUnCheck, refundApplyCheck } from '../api/refund-list'
export default {
  components: { ListStatusText, ListImage },
  data() {
    return {
      orderid: '',
      // 订单详情
      orderDetail: {
        orderGoods: [],
        order_price: 0,
        integral: 0,
        status_text: '',
        apply_refund_text: '',
      },
      loading: false,
    }
  },
  methods: {
    // 获取订单详情
    getOrderDetail() {
      this.loading = true
      getDetail({ orderid: this.orderid })
        .then((res) => {
          this.orderDetail = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 计算订单商品总和运费
    totalFreight(goods) {
      console.log(goods)
      let count = goods.reduce((prev, cur, index, arr) => {
        return prev + cur.postage_price
      }, 0)
      return count.toFixed(2)
    },
    // 计算订单商品总件
    totalNum(goods) {
      console.log(goods)
      let count = goods.reduce((prev, cur, index, arr) => {
        return prev + cur.amount
      }, 0)
      return count
    },
    // 计算订单商品总价
    totalPrice(goods) {
      console.log(goods)
      let count = goods.reduce((prev, cur, index, arr) => {
        return prev + cur.price
      }, 0)
      return count.toFixed(2)
    },
    // 同意退款
    agreeRow() {
      this.$confirm('是否同意退款?', '提示', {
        confirmButtonText: '同意退款',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '退款中...'
            refundApplyCheck({ orderid: this.orderDetail.orderid }).then(
              (res) => {
                instance.confirmButtonLoading = false
                this.$message.success('已同意退款')
                done()
              }
            )
          } else {
            instance.confirmButtonLoading = false
            done()
          }
        },
      })
        .then(() => {
          this.getOrderDetail()
        })
        .catch(() => {})
    },
    // 拒绝退款
    refuseRow() {
      this.$prompt('', '拒绝的原因', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '必须输入拒绝的原因才可以拒绝',
        beforeClose: (action, instance, done) => {
          console.log(action, instance)
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            if (instance.inputValue) {
              refundApplyUnCheck({
                orderid: this.orderDetail.orderid,
                refund_remarks: instance.inputValue,
              }).then((res) => {
                instance.confirmButtonLoading = false
                this.$message.success('已拒绝退款')
                done()
              })
            } else {
              this.$message.error('请输入拒绝的原因')
            }
          } else {
            instance.confirmButtonLoading = false
            done()
          }
        },
      })
        .then(({ value }) => {
          this.getOrderDetail()
        })
        .catch(() => {})
    },
    // 跳转订单详情
    goDetail() {
      this.$router.push({
        name: 'GoodsOrderDetail',
        params: {
          id: this.orderid,
        },
      })
    },
  },

  created() {
    this.orderid = this.$route.params.id
    this.getOrderDetail()
  },
}
</script>

<style lang="scss" scoped>
// 售后退款模块
.refund {
  padding: 10px;
  margin-bottom: 10px;

  background-color: #f3f3f3;
  .module {
    border-radius: 5px;
    background-color: #ffff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .refund-text {
        font-weight: 700;
        color: #3576ff;
      }
    }

    .right {
    }
  }
}
.grid-template {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  box-sizing: border-box;
  .left,
  .right {
    overflow: hidden;
  }
  .item {
    padding: 10px;
    margin-bottom: 10px;

    background-color: #f3f3f3;
    .module {
      border-radius: 5px;
      background-color: #ffff;
      padding: 20px;
    }
    .label-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .label {
        font-weight: 700;
      }
    }
    .title {
      margin-bottom: 30px;
      font-weight: 700;
    }
    .info {
      .info-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 8px;
        .label {
          width: 100px;
          color: #b3b3b3;
        }

        .value {
          flex: 1;
        }
      }
    }
  }
}
.goods-name {
  display: flex;

  .left {
    margin-right: 10px;
  }

  .right {
    .goods-attr {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-specs {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .more {
      el-button {
      }
    }
  }
}
.order_price {
  margin-top: 20px;
  .text {
    color: #b3b3b3;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .total {
    font-size: 16px;
    span {
      font-weight: 700;
    }
  }
}
</style>
